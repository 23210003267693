@import '../styles';

.warning-modal-overlay {
  position: fixed;
  background-color: $background;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .back-button-container{
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
  }
}

.add-printer-overlay {
  position: fixed;
  background-color: $background;
  width: 50vw;
  height: 50vh;
  display: flex;
  border: 1px solid $orange;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  left: 25%;

  .back-button-container{
    position: relative;
    top: 0;
    left: 0;
    margin: 20px;
  }
}

.btn-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    @extend %main-button;
    width: 200px;

    @include sm {
      margin: 40px 10px 0 10px;
      width: 200px;
    }
  }
}

.rules-questions-container {
  .yes-no-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 140.62%;
    text-align: center;
    flex-wrap: wrap;
    color: $orange !important;
  }

  .yes-button {
    width: 150px !important;
    height: 36px !important;
    background: $white;
    color: $orange;
    border-radius: 5px;
    box-shadow: none;
  }

  .no-button {
    color: $orange;
    width: 150px !important;
    height: 36px !important;
    background: $white;
    border-radius: 5px;
    box-shadow: none;
  }
}
