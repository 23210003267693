@import '../styles';

.nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: $display-shadow;
  cursor: pointer;

  p {
    width: 20%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background-color: $white;
    color: $black;
    font-size: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.247);

    &:hover {
      background-color: $orange;
      color: $white;
    }

    @include sm {
      font-size: 12px;
    }
  }
}

// general portal
.portal-container {
  display: grid;
  align-items: center;
  margin: auto;
  width: 80%;

  .components-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    h2 {
      margin: 20px 0;
      font-size: 24px;
      font-weight: 400;
    }

    p {
      margin-top: 10px;
    }

    .location-name {
      color: $orange;
      font-size: 20px;
    }

    .btnset-container {
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    .portal-btn-addlocation {
      @extend %secondary-button;
      width: 200px;
      height: 60px;
      margin: 0 0 0 10px;
      color: $white;
      border: 1px solid $orange;
      background-color: $orange;

      &:hover {
        color: $orange;
        border: 1px solid $orange;
        background-color: $white;
      }
    }

    .portal-btn-csv {
      display: block;
      width: 200px;
      height: 60px;
      border-radius: 5px;
      font-size: 20px;
      margin: 0 0 10px 0;
      line-height: 60px;
      text-decoration: none;
      color: $white;
      border: 1px solid $orange;
      background-color: $orange;
      box-shadow: $button-shadow;
      text-align: center;

      &:hover {
        color: $orange;
        border: 1px solid $orange;
        background-color: $white;
      }
    }

    .portal-btn-induction {
      display: block;
      width: 300px;
      height: 60px;
      border-radius: 5px;
      font-size: 20px;
      margin: 0 0 10px 0;
      line-height: 60px;
      text-decoration: none;
      color: $white;
      border: 1px solid $orange;
      background-color: $orange;
      box-shadow: $button-shadow;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: $orange;
        border: 1px solid $orange;
        background-color: $white;
      }
    }

    .location-selector {
      width: 250px;
      height: 60px;
      border: 1px solid $orange;
      padding-left: 17px;
      box-shadow: $display-shadow;
      border-radius: 5px;
      font-family: $font;
      font-size: 18px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    h2 {
      text-transform: capitalize;
    }
  }

  .warning-message {
    color: $orange;
  }

  .info-message {
    padding: 10px;
    background: darkseagreen;
    color: white;
    border-radius: 7px;
  }

  .btnQ-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .csv-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .residents-header {
      .questions-title {
        font-weight: 400;
        font-size: 24px;
      }

      .location-name {
      }
    }

    select {
      width: 150px;
      height: 60px;
      border: 1px solid $orange;
      padding-left: 17px;
      box-shadow: $display-shadow;
      border-radius: 5px;
      font-family: $font;
      font-size: 18px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;

    select {
      width: 200px;
      height: 40px;
      border: 1px solid $orange;
      padding-left: 17px;
      box-shadow: $display-shadow;
      border-radius: 5px;
      font-family: $font;
      font-size: 18px;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .search-container {
      display: flex;
      flex-direction: column;

      input {
        width: 200px;
        height: 40px;
        margin-bottom: 10px;
      }

      .month {
        margin-right: 10px;
      }
    }
  }
}

// rules and questions edit page
.edit-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: auto auto 30px;
  width: 100%;

  .textarea-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .location-name {
    color: $orange;
    font-size: 20px;
    margin-left: 10px;
  }

  textarea {
    width: 80%;
    height: 120px;
    padding: 10px;
    font-size: 20px;
    margin: 10px 0;
    border: 1px solid $grey;
    font-family: $font;

    &:focus {
      outline: none;
      border: 1px solid $orange;
    }
  }
}

// add manager
.add-container {
  display: grid;
  justify-content: center;
  margin: -10px 0;

  h2 {
    text-transform: capitalize;
  }
}

// display residents page
.resident-container-div{
  height: 70vh;
  width: 80vw;
}

.resident-container {
  display: grid;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  font-weight: 400;
  width: 100%;
  overflow: scroll;
  border-collapse: collapse;
  font-size: 20px;

  tbody {
    width: 100%;
    height: 500px;
  }

  th {
    font-weight: 500;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis;
    padding: 22px;
    min-width: 150px; /* Set a minimum width for the headers */
    max-width: 300px; /* Set a maximum width for the headers */
    width: auto; /* Allow the width to be determined by content */
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 22px;
    min-width: 150px; /* Set a minimum width for the cells */
    max-width: 300px; /* Set a maximum width for the cells */
    width: auto;
    white-space: initial;
  }

  .resident-data {
    background-color: #fff;
    border-bottom: 12px solid #f4f4f4;
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    font-size: .9em;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
    width: 100%;
    justify-content: space-around;
    display: flex;

    p {
      text-align: left;
      padding: 10px;
    }
    pre {
      text-align: left;
      padding: 10px;
    }

    .data-time {
      border: none;
    }

    .data-name {
      text-transform: capitalize;
    }
  }

  .resident-title, .settings-title {
    color: $grey;
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
    width: 100%;
    font-size: 0.9em;

    p {
      text-align: left;
      padding: 0 10px;
      word-break: break-word !important;
    }
  }
}

// .resident-title, .resident-data {
//   display: flex;
//   border-radius: 5px;
//   background-color: $white;
//   box-shadow: $display-shadow;
//   padding: 0 10px;
//   white-space: nowrap;

//   p {
//     text-align: left;
//     line-height: 50px;
//     width: 100%;
//     padding: 0 10px;
//     font-size: 18px;
//     font-weight: 400;
//     margin: 0;
//   }
// }

// .resident-title {
//   color: $grey;
//   justify-content: space-between;
// }

// .data-name {
//   text-transform: capitalize;
// }

// location details
.details-action-container {
  display: flex;
  background: $white;
  box-shadow: $display-shadow;
  border-radius: 5px;
  margin-bottom: 20px;

  .location-details-container {
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;

    div {
      width: 95%;
    }

    .location-detail {
      display: flex;
      gap: 10rem;
      margin: 20px;
      font-weight: 400;
      font-size: 19px;

      .location-title {
        color: $grey;
        text-transform: capitalize;
        width: 200px;
      }

      p:nth-child(2) {
        // width: 250px;
        // height: 60px;
        // border: 1px solid $orange;
        // padding-left: 17px;
        // box-shadow: $display-shadow;
        // border-radius: 5px;
        font-family: $font;
        text-align: left;
        // cursor: pointer;
      }
    }
  }
}

.details-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputs-details-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;

  div {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  p,
  input {
    margin: 10px;
  }

  label {
    padding: 10px;
  }

  span {
    font-size: 14px;
  }
}

.delete-btn {
  @extend %secondary-button;
  color: $red;
  border: 1px solid $red;
  background-color: $white;
  margin: 0 10px;

  &:hover {
    color: $white;
    border: 1px solid $red;
    background-color: $red;
  }
}

.deactive-btn {
  @extend %secondary-button;
  color: $white;
  background-color: $red;
  margin: 0 10px;
  width: 120px;

  &:hover {
    color: $red;
    border: 1px solid $red;
    background-color: $white;
  }
}
.details-btn-container,
.portal-right-side-button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .portal-btn-generateQR {
    @extend %secondary-button;
    width: 206px;
    height: 41.7px;
    color: #fff;
    border: 1px solid $orange;
    background-color: $orange;
    font-size: 1.2rem;
    font-weight: 400;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 0 0 0 10px;
  }
}

.active-btn {
  @extend %secondary-button;
  color: $white;
  background-color: $green;
  margin: 0 10px;
  width: 120px;

  &:hover {
    color: $green;
    border: 1px solid $green;
    background-color: $white;
  }
}

.save-btn {
  @extend %secondary-button;
  color: $white;
  border: 1px solid $orange;
  background-color: $orange;
  margin: 0 10px;

  &:hover {
    color: $orange;
    border: 1px solid $orange;
    background-color: $white;
  }
}

.add-btn,
.edit-btn,
.back-btn {
  @extend %secondary-button;
  margin: 0 10px 0 10px;
  color: $orange;
  border: 1px solid $orange;
  background-color: $white;

  &:hover {
    background-color: $orange;
    color: $white;
  }
}

.update-message {
  color: $orange;
  font-size: 20px;
}
.settings-container {
  width: 90%;
  margin-top:5px;
}

.settings-title {
  /* Apply styles to the table element */
  table {
    width: 100%; /* Make the table expand to the full width of its container */
    border-collapse: collapse; /* Collapse the table borders into a single border */
  }

  /* Style for table header row */
  th {
    background-color: #f2f2f2; /* Background color for header cells */
    text-align: left; /* Align text to the left in header cells */
    padding: 8px; /* Add padding to header cells */
  }

  /* Style for table data rows */
  td {
    padding: 8px; /* Add padding to data cells */
  }

  /* Add alternate row background color */
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  /* Hover effect on rows */
  tr:hover {
    background-color: #e0e0e0
  }
  }
