@import './config';
@import './mixins';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: $background;
  font-family: $font;
  position: relative;
  min-height: 100vh;
}

.main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  user-select: none;
  min-height: 100%;

  h2 {
    text-transform: capitalize;
  }
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;

  .logo {
    width: 200px;
  }
}

.loading {
  width: 100px;
}

.client-logo {
  margin: 20px 0 20px 0;
  width: 35%;
}

.client-qr {
  width: 200px;
}

.return-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  p {
    color: $orange;
    text-decoration: underline;
    margin-top: 20px;
    cursor: pointer;
  }

  button {
    @extend %main-button;
  }

  .return-box {
    width: 70%;
    padding: 30px;
    border-radius: 10px;
    box-shadow: $display-shadow;
    background-color: $white;

    h2 {
      color: $orange;
    }

    h3,
    p {
      margin-top: 20px;
    }
  }
}

.website-link {
  margin-bottom: 30px;
  color: $orange;
  font-size: 14px;
}

%main-button {
  width: 300px;
  height: 60px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 40px;
  border: none;
  cursor: pointer;
  background-color: $orange;
  font-family: $font;
  color: $white;
  box-shadow: $button-shadow;

  &:hover {
    background-color: $white;
    border: 1px solid $orange;
    color: $orange;
  }

  &:focus {
    outline: none;
  }
}

%secondary-button {
  width: 80px;
  height: 40px;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 40px;
  border: none;
  cursor: pointer;
  font-family: $font;
  box-shadow: $button-shadow;

  &:focus {
    outline: none;
  }
}

.bar {
  position: fixed;
  background-color: $orange;
  height: 7px;
}
