@import '../styles.scss';

.back-button-container {
  display: flex;
  justify-content: flex-start !important;
  margin-top: 15px;
  width: 100%;
  padding: 0 20px;
  height: 45px;

  @include sm{
    font-size: 0.8rem;
    font-weight:400;

  }
}

.back-button {
  width: 150px !important;
  height: 36px !important;
  background: #fff;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 140.62%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f66d51;
  cursor: pointer;
}

.information-form-title {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140.62%;
  color: #000;
  margin-bottom: 20px;
  text-align: center;
}

.main-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .return-container {
    .return-box {
      h2,
      h3 {
        font-weight: 500;
        font-size: 1.4rem;
      }
    }
  }
}

.input-wrapper {
  display: grid;
  border: 1px;
  padding: 5px 0;

  input,
  select,
  select option {
    padding: 10px 10px 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    height: 60px;
    border: 1px solid $grey;
    background-color: $white;
    color: $black;
    font-family: $font;

    &:focus {
      outline: none;
      border: 1px solid $orange;
    }
  }
}

select:not(:-internal-list-box) {
  overflow: visible !important;
}

.select-width {
  width: 100%;
}

.website-link {
  margin-bottom: 30px;
  color: #f66d51;
  font-size: 1rem;
  text-decoration: none;
}
